<template>
  <section class="pt-5">
    <b-container fluid class="pb-4 px-lg-4">
      <b-row v-if="checkoutResult">
        <b-col xs="12" sm="12" md="12" lg="10" offset-lg="1">
          <LogoCard
            :img="checkoutResult.business_logo"
            :alt="checkoutResult.business_name"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <CheckoutSuccess v-if="paymentSuccess">
            <template>
              <div class="mt-4 text-center" v-if="checkoutResult">
                <small class="text-color-black">Total pagado</small>
                <h3 class="h3 font-bold mt-2">
                  <FormatAmount :amount="checkoutResult.total" />
                </h3>
              </div>
            </template>
          </CheckoutSuccess>

          <CheckoutError v-if="paymentError">
            <template>
              <div class="mt-4 text-center">
                <p class="text-center">
                  No se ha podido completar la validación del pago, <br />por
                  favor intente nuevamente o escriba a
                  <span class="font-bold text-color-secondary"
                    >soporte@mydessk.com</span
                  >
                </p>
              </div>
            </template>
          </CheckoutError>
        </b-col>
      </b-row>
    </b-container>

    <div class="d-flex justify-content-center">
      <ButtonText
        text="Regresar"
        classNames="text-color-secondary"
        @onClick="onClickBack"
      />
    </div>
  </section>
</template>

<script>
import CheckoutSuccess from "../componets/CheckoutSuccess";
import CheckoutError from "../componets/CheckoutError";
import checkoutService, { messageWaiting } from "../services/checkoutService";
import FormatAmount from "@/components/General/FormatAmount";
import LogoCard from "@/components/Logo/LogoCard";
import ButtonText from "@/components/Buttons/ButtonText";

export default {
  name: "CheckoutPayLinksPage",
  data() {
    return {
      id: null,
      clientTransactionId: null,
      checkoutResult: null,
      paymentSuccess: null,
      paymentError: null
    };
  },
  methods: {
    onClickBack() {
      this.$router.replace({ path: "/register" });
    },
    async checkoutPayLink() {
      this.$store.dispatch("toggleRequestLoading", {
        text: messageWaiting
      });
      try {
        this.paymentSuccess = false;
        this.paymentError = false;

        let response = await checkoutService
          .confirmPayLink({
            transaction: this.id,
            client: this.clientTransactionId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.paymentSuccess = true;
          this.checkoutResult = data;
        } else {
          this.paymentError = true;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    CheckoutSuccess,
    CheckoutError,
    FormatAmount,
    LogoCard,
    ButtonText
  },
  mounted() {
    const { id, clientTransactionId } = this.$route.query;
    if (id && clientTransactionId) {
      this.id = id;
      this.clientTransactionId = clientTransactionId;
      this.checkoutPayLink();
    } else {
      this.$router.push({ name: "404" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
